<template>
    <div class="main-container">
      <div class="section-topic">
        <div class="title-header">
          <h1>{{ $t("educationLevel.title") }}</h1>
        </div>
      </div>
      <div class="section-action">
        <div class="search-action">
          <v-text-field
              class="btn btn-color btn-border btn-border-color"
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('Search.search')"
              v-model="searchItem"
              @keypress.enter="searchFilterItem"
          >
          </v-text-field>
        </div>
        <div class="btn-new-create-action">
          <v-btn class="btn-create" @click="onCreate()">{{ $t("educationLevel.create") }}</v-btn>
        </div>
      </div>
      <div class="section-form-content">
        <div class="block-content">
          <table class="table table-view" v-if="lists.length > 0">
            <thead>
            <tr>
              <th class="text-left">{{ $t("educationLevel.no") }}</th>
              <th class="text-left">{{ $t("educationLevel.name") }}</th>
              <th class="text-left">{{ $t("educationLevel.amount") }}</th>
              <th class="text-left">{{ $t("educationLevel.created") }}</th>
              <th class="text-left">{{ $t("educationLevel.updated") }}</th>
              <th class="text-left"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in lists" :key="index">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ $helpers.numberFormatter(item.budget) }}
              </td>
              <td>
                {{ formatDate(item.createdAt) }}
              </td>
              <td>
                {{ formatDate(item.updatedAt) }}
              </td>
              <td>
  
              </td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                          class="btn-edit"
                          @click="onEdit(item.id)"
                      >{{ $t("education.edit") }}
                      </v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                          class="btn-delete"
                          @click="onDeleted(item.id)"
                      >{{ $t("education.delete") }}
                      </v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            </tbody>
          </table>
          <defaultTableNoResult v-else/>
          <Loading v-if="isLoading"/>
          <ModalEdit :title="$t('educationLevel.title')">
            <template v-slot="{ close }">
              <EditComponent
                  @close="close"
                  :item="item"
                  @success="fetchData"
              />
            </template>
          </ModalEdit>
  
          <ModalDelete>
            <template v-slot="{ close }">
              <DeleteComponent
                  @close="close"
                  @success="fetchData"
                  :id="id"
              />
            </template>
          </ModalDelete>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import EditComponent from "@/components/EducationLevel/Edit";
  import DeleteComponent from "@/components/EducationLevel/Delete";
  import Loading from "../../../components/Loading";
  import defaultTableNoResult from "../../../components/defaultTableNoResult";
  import moment from "moment";
  
  export default {
    components: {
      EditComponent,
      DeleteComponent,
      Loading,
      defaultTableNoResult,
    },
    data() {
      return {
        isLoading: true,
        lists: [],
        item: {},
        id: "",
        searchItem: "",
      };
    },
    methods: {
      formatDate(date) {
        return moment(date).format("YY-MM-DD")
      },
      searchFilterItem() {
        this.fetchData();
      },
      filterItem(id) {
        return (
            this.lists.filter((item) => {
              return item.id == id;
            })[0] || {}
        );
      },
  
      onCreate() {
        this.$router.push({
          name: "education.level.create",
        }).catch(() => {
        });
      },
  
      onEdit(id) {
        this.item = {
          ...this.filterItem(id),
        };
        this.$store.commit("modalEdit_State", true);
      },
  
      onDeleted(id) {
        this.id = id;
        this.$store.commit("modalDelete_State", true);
      },
  
      fetchData() {
        this.isLoading = true;
        this.$axios
            .get(`company/education-level`, {})
            .then((res) => {
              if (res.status === 200) {
                this.isLoading = false;
                this.lists = res.data.data;
              }
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
            });
      },
    },
    created() {
      this.fetchData();
    },
  };
  </script>
  
  <style scoped lang="scss">
  </style>
  